.mobile-nav {
  min-height: 100px;
  position: relative;

  .mobile-logo {
    width: 55px;
    height: 45px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
  }

  .profile-holder {
    position: absolute;
    right: 25px;
    top: 35px;
  }

  .mobile-nav__list {
    li {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    a {
      display: block;
      padding: 15px 30px 15px 0;
      font-size: 24px;
    }

    i {
      font-size: 14px;
      color: $tirques;
    }
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    right: 25px;
    top: 36px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: $white;
    height: 3px !important;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 30px !important;
    width: 30px !important;
    left: 20px;
    top: 30px !important;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    height: 30px !important;
    width: 4px !important;
    background: $white;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #000000;
    padding: 100px 25px 35px;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}
