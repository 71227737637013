.footer {
  font-family: $font-family-1;
  min-height: $footer-height;
}

.footer-holder {
  display: flex;
  justify-content: space-between;
}

.footer-logo {
  width: 44px;
  height: 33px;
  margin-right: 55px;

  img {
    width: 100%;
  }
}

.footer-nav {
  display: flex;
  align-items: center;

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.footer__link {
  @extend .header__link;
  font-size: 17px;
}

.footer-socials {
  display: flex;
  align-items: center;

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    margin: 0 15px;
  }
}

.footer-socials__link {
  color: $white;
  padding: 0 5px;
  transition: all 0.1s ease;

  &:hover {
    opacity: 0.7;
  }
}

//
.footer {
  padding: 0 4rem;
}
