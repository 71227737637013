.title-primary {
  font-size: 72px;
  font-weight: 400;
  text-transform: uppercase;
  color: $white;
  line-height: 0.86;
  letter-spacing: -1.5px;
  text-align: center;
  font-family: $font-family-1;
}

.back-link {
  text-decoration: underline;
  color: $black;
}

.main {
  min-height: $main-height;
}

.content-bg {
  padding: 70px 0;
  min-height: $main-height;
  background-repeat: no-repeat;
  background-position: left top;
}
