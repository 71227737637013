@font-face {
  font-family: 'BebasNeue';
  src: url('../fonts/BebasNeue-Light.ttf'), url('../fonts/BebasNeue-Light.eot');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'BebasNeue';
  src: url('../fonts/BebasNeue.ttf'), url('../fonts/BebasNeue.eot');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'BebasNeue';
  src: url('../fonts/BebasNeue-Regular.ttf'),
    url('../fonts/BebasNeue-Regular.eot');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'BebasNeue';
  src: url('../fonts/BebasNeue-Bold.ttf'), url('../fonts/BebasNeue-Bold.eot');
  font-style: normal;
  font-weight: 700;
}
