.css-2b097c-container {
  width: 100% !important;
  font-size: 17px;
  justify-content: space-around;

  img {
    margin-right: 10px;
  }
}

.css-yk16xz-control {
  height: 30px !important;
  border: none !important;
  outline: none !important;
  background: transparent !important;
}

.css-1uccc91-singleValue {
  min-width: 100%;
}
.customOption {
  border-bottom: 0;
  background: transparent !important;
  img {
    width: 30px;
    height: 20px;
    // margin-right: 10px;
  }
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-w8afj7-Input {
  height: 30px;
  outline: none;
  max-width: 175px;
  width: 100%;
  transition: all 0.3s ease;
  font-size: 17px;
  font-weight: normal;
}
.css-109p05i-control {
  border: none !important;
}

.css-2b097c-container {
  border-bottom: none !important;
}
