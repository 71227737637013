.axe-music-header {
  .axe-introduction {
    padding-top: 9rem;
    padding-left: 9rem;

    @media (max-width: 990px) {
      padding: 1rem;
    }
  }
}
