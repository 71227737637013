// colors
$black: #000000;
$white: #ffffff;
$lightbrown: #a4896c;
$pink: #ff0c69;
$tirques: #00ffe7;
$red: #ff0009;
$purple-text: #5b00ff;
$black-text: #323232;
$lightgray: #969696;

$header-height: 184px;
$footer-height: 45px;
$main-height: calc(100vh - #{$header-height} - 77px);

$font-family-1: 'BebasNeue';
$font-family-2: 'Arial';
