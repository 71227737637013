.header__link {
  font-size: 24px;
  color: $white;
  text-transform: uppercase;
  font-weight: 300;
  position: relative;

  &.active {
    &::after {
      content: '';
      position: absolute;
      width: 0px;
      height: 0px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid $tirques;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -20px;
      cursor: auto;
    }
  }

  &.active-pink {
    &::after {
      border-bottom: 15px solid $pink;
    }
  }
}

ul.header-nav {
  li {
    font-size: 0;
    margin-right: 60px;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-block;
      text-align: center;

      &:hover {
        font-weight: bold;
      }

      &.active {
        font-weight: bold;
      }

      &::before {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }

    .pipe {
      display: inline-block;
      width: 1px;
      height: 20px;
      background: $tirques;
    }

    @media (max-width: 1220px) {
      margin-right: 35px;
    }
  }
}

.subheader-link {
  font-size: 18px;
  color: $black-text;
  font-weight: 500;
}

ul.subheader-nav {
  padding: 15px 0;
  display: flex;

  li {
    font-size: 0;
    margin-right: 40px;

    @media (max-width: 768px) {
      margin-right: 30px;
    }

    &:last-child {
      margin-right: 0;

      @media (max-width: 768px) {
        padding-right: 15px;
      }
    }

    a {
      display: inline-block;
      text-align: center;

      &:hover {
        font-weight: bold;
        color: $purple-text;
      }

      &.active {
        font-weight: bold;
        color: $purple-text;
      }

      &::before {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }

  &.pink {
    a {
      color: $white;

      &:hover {
        font-weight: bold;
        color: $tirques;
      }

      &.active {
        font-weight: bold;
        color: $tirques;
      }
    }
  }
}
