.auth-page-layout__wrapper {
  // .label-txt {
  //   text-align: left;
  //   color: $black;
  //   min-width: 100px;
  // }

  // input field
  .label {
    // justify-content: flex-end;
  }

  .input-field {
    color: $black;
    max-width: 175px;
    font-weight: 400;
    background: transparent;

    @media (max-width: 500px) {
      max-width: 100%;
    }
  }

  .forgot-password {
    .form-holder {
      max-width: 370px;
    }

    .label-txt {
      max-width: 95px;
    }

    .input-field {
      max-width: 195px;
    }

    .btn {
      margin-top: 50px;
    }
  }

  .label-txt {
    color: $black;
    text-align: right;
    min-width: 115px;

    @media (max-width: 500px) {
      min-width: 100px;
    }
  }

  height: 100vh;
  position: relative;

  .btn {
    background: $lightbrown;
    margin: 0 auto;
    margin-top: 35px;

    &:hover {
      background: darken($lightbrown, 10%);
    }
  }
}

.auth-logo {
  position: absolute;
  right: 50px;
  bottom: 40px;
  width: 175px;
  height: 67px;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

.auth-page-layout__content {
  background: #fff;
  min-height: 100%;
  max-width: 620px;
  margin: 0 auto;
  transform: skew(-5deg);
  text-align: center;
  padding: 145px 0 100px;
  color: $black-text;

  @media (max-width: 500px) {
    transform: skew(0deg);
    display: flex;
    align-items: center;
  }
}

.auth-page-layout__content--holder {
  margin: 0 auto;
  width: 85%;
  transform: skew(5deg);

  @media (max-width: 500px) {
    transform: skew(0deg);
  }
}

.title-primary {
  color: $lightbrown;
  margin-bottom: 30px;
}

.title-subtext {
  padding: 0 20px;

  &.bold {
    margin-bottom: 20px;
    font-weight: bold;
  }

  &.welcome {
    padding: 0 80px;
    margin-bottom: 30px;
  }

  &.confirmation {
    margin-top: 10px;
  }
}

.auth-link-text {
  color: $lightbrown;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

.form-holder {
  margin: 20px auto;
  max-width: 350px;
  width: 100%;
  padding: 0 20px;

  @media (max-width: 500px) {
    padding: 0;
  }
}

.back-link {
  margin-top: 45px;
  display: block;
}

.password-reset {
  .btn {
    margin-top: 80px;
    margin-bottom: 50px;
  }
}

.reset-password {
  .form-holder {
    margin-bottom: 50px;
  }
}

.signed-out {
  .title-subtext {
    margin-bottom: 70px;
  }
}

.input-field {
  color: white;
  max-width: 175px;
  font-weight: 400;
  background: transparent;
}

.forgot-password {
  .form-holder {
    max-width: 370px;
  }

  .label-txt {
    max-width: 95px;
  }

  .input-field {
    max-width: 195px;
  }

  .btn {
    margin-top: 50px;
  }
}

.forgot-pass {
  text-align: right;

  a {
    font-size: 14px;
    color: $black-text;

    &:hover {
      text-decoration: underline;
    }
  }
}

.sign-in {
  .input-field__holder:nth-child(2) {
    margin-bottom: 0;
  }
}
