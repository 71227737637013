* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  min-height: 100%;
}

body {
  background: $black;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  font-weight: 300;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#root {
  min-height: 100vh;
  position: relative;
}

button {
  outline: none !important;
}

.label-txt {
  text-align: left;
  color: $tirques;
  min-width: 100px;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}

input[type='radio'] {
  margin: 0 6px;
}
