html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
footer,
header,
nav,
section {
  display: block; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

figcaption,
figure,
main {
  display: block; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

dfn {
  font-style: italic; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

audio,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  display: inline-block;
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details,
menu {
  display: block; }

summary {
  display: list-item; }

canvas {
  display: inline-block; }

template {
  display: none; }

[hidden] {
  display: none; }

ol,
ul {
  list-style: none; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  min-height: 100%; }

body {
  background: #000000;
  min-height: 100vh; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0; }

a {
  text-decoration: none;
  font-weight: 300; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

#root {
  min-height: 100vh;
  position: relative; }

button {
  outline: none !important; }

.label-txt {
  text-align: left;
  color: #00ffe7;
  min-width: 100px; }

.disabled-link {
  pointer-events: none;
  opacity: 0.5; }

input[type='radio'] {
  margin: 0 6px; }

@font-face {
  font-family: 'BebasNeue';
  src: url("../fonts/BebasNeue-Light.ttf"), url("../fonts/BebasNeue-Light.eot");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'BebasNeue';
  src: url("../fonts/BebasNeue.ttf"), url("../fonts/BebasNeue.eot");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'BebasNeue';
  src: url("../fonts/BebasNeue-Regular.ttf"), url("../fonts/BebasNeue-Regular.eot");
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: 'BebasNeue';
  src: url("../fonts/BebasNeue-Bold.ttf"), url("../fonts/BebasNeue-Bold.eot");
  font-style: normal;
  font-weight: 700; }

.title-primary {
  font-size: 72px;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 0.86;
  letter-spacing: -1.5px;
  text-align: center;
  font-family: "BebasNeue"; }

.back-link {
  text-decoration: underline;
  color: #000000; }

.main {
  min-height: calc(100vh - 184px - 77px); }

.content-bg {
  padding: 70px 0;
  min-height: calc(100vh - 184px - 77px);
  background-repeat: no-repeat;
  background-position: left top; }

body {
  color: #ffffff;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.12;
  letter-spacing: normal; }

.input-field__holder {
  margin: 15px 0; }

.input-field {
  height: 30px;
  border: none;
  outline: none;
  border-bottom: 0.5px solid #969696;
  max-width: 175px;
  width: 100%;
  transition: all 0.3s ease;
  font-size: 17px;
  font-weight: normal; }
  .input-field::placeholder {
    color: #969696; }

.label {
  font-family: "BebasNeue";
  font-size: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  position: relative; }

.password-eye {
  position: absolute;
  width: 13px;
  height: 12px;
  right: 0;
  bottom: 5px; }
  .password-eye > i {
    width: 13px;
    height: 12px;
    font-size: 12px;
    position: absolute;
    top: 0;
    left: -5px;
    cursor: pointer; }

.input-error__sign {
  position: absolute;
  right: -30px;
  width: 22px;
  height: 22px;
  background: #ff0009;
  color: #ffffff;
  font-size: 14px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 500px) {
    .input-error__sign {
      right: -20px; } }

.label-txt {
  margin-right: 20px; }

.input-error {
  border-color: #ff0009;
  color: #ff0009;
  position: relative; }
  .input-error:focus {
    border-color: #ff0009; }

.header__link, .footer__link {
  font-size: 24px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 300;
  position: relative; }
  .header__link.active::after, .active.footer__link::after {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #00ffe7;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -20px;
    cursor: auto; }
  .header__link.active-pink::after, .active-pink.footer__link::after {
    border-bottom: 15px solid #ff0c69; }

ul.header-nav li {
  font-size: 0;
  margin-right: 60px; }
  ul.header-nav li:last-child {
    margin-right: 0; }
  ul.header-nav li a {
    display: inline-block;
    text-align: center; }
    ul.header-nav li a:hover {
      font-weight: bold; }
    ul.header-nav li a.active {
      font-weight: bold; }
    ul.header-nav li a::before {
      display: block;
      content: attr(title);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden; }
  ul.header-nav li .pipe {
    display: inline-block;
    width: 1px;
    height: 20px;
    background: #00ffe7; }
  @media (max-width: 1220px) {
    ul.header-nav li {
      margin-right: 35px; } }

.subheader-link {
  font-size: 18px;
  color: #323232;
  font-weight: 500; }

ul.subheader-nav {
  padding: 15px 0;
  display: flex; }
  ul.subheader-nav li {
    font-size: 0;
    margin-right: 40px; }
    @media (max-width: 768px) {
      ul.subheader-nav li {
        margin-right: 30px; } }
    ul.subheader-nav li:last-child {
      margin-right: 0; }
      @media (max-width: 768px) {
        ul.subheader-nav li:last-child {
          padding-right: 15px; } }
    ul.subheader-nav li a {
      display: inline-block;
      text-align: center; }
      ul.subheader-nav li a:hover {
        font-weight: bold;
        color: #5b00ff; }
      ul.subheader-nav li a.active {
        font-weight: bold;
        color: #5b00ff; }
      ul.subheader-nav li a::before {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden; }
  ul.subheader-nav.pink a {
    color: #ffffff; }
    ul.subheader-nav.pink a:hover {
      font-weight: bold;
      color: #00ffe7; }
    ul.subheader-nav.pink a.active {
      font-weight: bold;
      color: #00ffe7; }

.footer {
  font-family: "BebasNeue";
  min-height: 45px; }

.footer-holder {
  display: flex;
  justify-content: space-between; }

.footer-logo {
  width: 44px;
  height: 33px;
  margin-right: 55px; }
  .footer-logo img {
    width: 100%; }

.footer-nav {
  display: flex;
  align-items: center; }
  .footer-nav ul {
    display: flex;
    flex-wrap: wrap; }
  .footer-nav li {
    margin-right: 30px; }
    .footer-nav li:last-child {
      margin-right: 0; }

.footer__link {
  font-size: 17px; }

.footer-socials {
  display: flex;
  align-items: center; }
  .footer-socials ul {
    display: flex;
    flex-wrap: wrap; }
  .footer-socials li {
    margin: 0 15px; }

.footer-socials__link {
  color: #ffffff;
  padding: 0 5px;
  transition: all 0.1s ease; }
  .footer-socials__link:hover {
    opacity: 0.7; }

.footer {
  padding: 0 4rem; }

.profile-page-layout .title-primary {
  text-align: left;
  color: #ffffff;
  margin-bottom: 0; }
  @media (max-width: 768px) {
    .profile-page-layout .title-primary {
      margin-bottom: 30px; } }

.profile-page-layout .profile-content {
  max-width: 750px;
  width: 100%; }

.profile-page-layout .profile-content__holder {
  display: flex;
  justify-content: flex-end; }

.profile-page-layout .profile__title-holder {
  margin-bottom: 60px; }

.profile-page-layout .my-profile__title-holder {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media (max-width: 768px) {
    .profile-page-layout .my-profile__title-holder {
      justify-content: center; } }

.profile-page-layout .my-profile__buttons {
  display: flex;
  margin-left: 60px;
  flex-wrap: wrap; }
  @media (max-width: 768px) {
    .profile-page-layout .my-profile__buttons {
      margin-left: 0; } }
  @media (max-width: 460px) {
    .profile-page-layout .my-profile__buttons {
      justify-content: center; }
      .profile-page-layout .my-profile__buttons > a {
        margin-bottom: 10px; }
      .profile-page-layout .my-profile__buttons a {
        margin-left: 0; } }

.profile-page-layout .btn.dark {
  background-color: #000000;
  color: #ffffff;
  border: 1.5px solid #00ffe7;
  margin-right: 10px; }

.profile-page-layout .btn.tirques {
  background-color: #00ffe7;
  color: #000000;
  border: 1.5 solid #00ffe7; }

.profile-page-layout .profile__content-holder {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 768px) {
    .profile-page-layout .profile__content-holder {
      flex-wrap: wrap;
      padding-left: 50px; } }
  @media (max-width: 375px) {
    .profile-page-layout .profile__content-holder {
      padding-left: 20px; } }

.profile-page-layout .details-box {
  margin-bottom: 35px; }

.profile-page-layout .details-box__left {
  padding-right: 20px; }

.profile-page-layout .label {
  color: #00ffe7;
  justify-content: flex-start; }

.profile-page-layout .details-box__holder {
  display: flex;
  padding-left: 25px;
  margin-top: 8px; }
  @media (max-width: 768px) {
    .profile-page-layout .details-box__holder {
      padding-left: 0; } }

.profile-page-layout .profile-details__label {
  font-weight: bold;
  margin: 8px 0; }

.profile-page-layout .profile-details__name {
  margin: 8px 0; }

.profile-page-layout .profile__content--left,
.profile-page-layout .profile__content--right {
  min-width: 275px; }

.profile-page-layout .input-field__holder {
  margin-bottom: 30px; }

.profile-page-layout .input-field {
  border-color: #ffffff;
  color: #ffffff;
  max-width: 100%;
  font-family: 'Arial'; }

.profile-page-layout .label {
  flex-direction: column;
  align-items: flex-start; }

.profile-page-layout .label-txt {
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 5px; }

.profile-page-layout i.fas {
  color: #ffffff;
  right: 10px; }

.profile-page-layout .input-error__sign {
  top: 30px; }

.edit-profile,
.change-password {
  display: flex;
  flex-direction: column;
  min-height: 470px; }
  .edit-profile .my-profile__buttons,
  .change-password .my-profile__buttons {
    margin-left: 0;
    display: flex;
    justify-content: flex-end;
    margin-top: auto; }
    @media (max-width: 768px) {
      .edit-profile .my-profile__buttons,
      .change-password .my-profile__buttons {
        justify-content: center; } }
    @media (max-width: 450px) {
      .edit-profile .my-profile__buttons button,
      .change-password .my-profile__buttons button {
        margin-left: 0; } }

.btn {
  width: 190px;
  height: 45px;
  border: none;
  border-radius: 4px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: all 0.1s ease;
  text-transform: uppercase;
  font-family: "BebasNeue";
  font-weight: 400;
  font-size: 24px;
  color: #ffffff; }
  .btn:hover {
    cursor: pointer; }
  .btn.small {
    width: 175px; }
  .btn.wide {
    width: 215px; }
  .btn.btn-border-black {
    border: 1.5px solid #000000; }
  .btn.btn-border-white {
    border: 1.5px solid #ffffff; }
  .btn.btn-border-lightbrown {
    border: 1.5px solid #a4896c; }
  .btn.btn-border-pink {
    border: 1.5px solid #ff0c69; }
  .btn.btn-border-tirques {
    border: 1.5px solid #00ffe7; }
  .btn.btn-border-red {
    border: 1.5px solid #ff0009; }
  .btn.btn-text-black {
    color: #000000; }
  .btn.btn-text-white {
    color: #ffffff; }
  .btn.btn-text-lightbrown {
    color: #a4896c; }
  .btn.btn-text-pink {
    color: #ff0c69; }
  .btn.btn-text-tirques {
    color: #00ffe7; }
  .btn.btn-text-red {
    color: #ff0009; }
  .btn.btn-bg-black {
    background-color: #000000; }
  .btn.btn-bg-white {
    background-color: #ffffff; }
  .btn.btn-bg-lightbrown {
    background-color: #a4896c; }
  .btn.btn-bg-pink {
    background-color: #ff0c69; }
  .btn.btn-bg-tirques {
    background-color: #00ffe7; }
  .btn.btn-bg-red {
    background-color: #ff0009; }
  .btn.btn-bg-transparent {
    background-color: transparent; }

/* Common, default styles for the notification box */
.s-alert-box,
.s-alert-box * {
  box-sizing: border-box; }

.s-alert-box {
  position: fixed;
  background: black;
  padding: 22px;
  line-height: 1.4;
  z-index: 1000;
  pointer-events: none;
  color: rgba(250, 251, 255, 0.95);
  font-size: 100%;
  font-family: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
  max-width: 300px;
  -webkit-transition: top 0.4s, bottom 0.4s;
  transition: top 0.4s, bottom 0.4s; }

.s-alert-box.s-alert-show {
  pointer-events: auto; }

.s-alert-box a {
  color: inherit;
  opacity: 0.7;
  font-weight: 700; }

.s-alert-box a:hover,
.s-alert-box a:focus {
  opacity: 1; }

.s-alert-box p {
  margin: 0; }

.s-alert-box.s-alert-show,
.s-alert-box.s-alert-visible {
  pointer-events: auto; }

.s-alert-close {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 4px;
  top: 4px;
  overflow: hidden;
  text-indent: 100%;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.s-alert-close:hover,
.s-alert-close:focus {
  outline: none; }

.s-alert-close::before,
.s-alert-close::after {
  content: '';
  position: absolute;
  width: 3px;
  height: 60%;
  top: 50%;
  left: 50%;
  background: #fff; }

.s-alert-close:hover::before,
.s-alert-close:hover::after {
  background: #fff; }

.s-alert-close::before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg); }

.s-alert-close::after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg); }

/* positions */
.s-alert-bottom-left {
  top: auto;
  right: auto;
  bottom: 30px;
  left: 30px; }

.s-alert-top-left {
  top: 30px;
  right: auto;
  bottom: auto;
  left: 30px; }

.s-alert-top-right {
  top: 30px;
  right: 30px;
  bottom: auto;
  left: auto; }

.s-alert-bottom-right {
  /*default*/
  top: auto;
  right: 30px;
  bottom: 30px;
  left: auto; }

.s-alert-bottom {
  width: 100%;
  max-width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto; }

.s-alert-top {
  width: 100%;
  max-width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto; }

/* conditions */
.s-alert-info {
  border: 3px solid #00a2d3;
  color: #00a2d3; }

.s-alert-success {
  border: 3px solid #00ffe7;
  color: #00ffe7; }

.s-alert-warning {
  border: 3px solid #f1c40f;
  color: #f1c40f; }

.s-alert-error {
  border: 3px solid #ff0c69;
  color: #ff0c69; }

[class^='s-alert-effect-'].s-alert-hide,
[class*=' s-alert-effect-'].s-alert-hide {
  -webkit-animation-direction: reverse;
  animation-direction: reverse; }

/* height measurement helper */
.s-alert-box-height {
  visibility: hidden;
  position: fixed; }

.custom-ReactTable {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: Arial, Helvetica, sans-serif; }

.custom-ReactTable * {
  box-sizing: border-box; }

.custom-ReactTable .rt-table {
  -webkit-box-flex: 1;
  -ms-flex: auto 1;
  flex: auto 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto; }

.custom-ReactTable .rt-thead {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: bold; }

.custom-ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.custom-ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.custom-ReactTable .rt-thead.-filters input,
.custom-ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none; }

.custom-ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02); }

.custom-ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid red; }

.custom-ReactTable .rt-thead .rt-tr {
  text-align: center; }

.custom-ReactTable .rt-thead .rt-th,
.custom-ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent; }

.custom-ReactTable .rt-thead .rt-th.-sort-asc,
.custom-ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6); }

.custom-ReactTable .rt-thead .rt-th.-sort-desc,
.custom-ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6); }

.custom-ReactTable .rt-thead .rt-th.-cursor-pointer,
.custom-ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer; }

.custom-ReactTable .rt-thead .rt-th:last-child,
.custom-ReactTable .rt-thead .rt-td:last-child {
  border-right: 0; }

.custom-ReactTable .rt-thead .rt-th:focus {
  outline: none; }

.custom-ReactTable .rt-thead .rt-resizable-header {
  overflow: visible; }

.custom-ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden; }

.custom-ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis; }

.custom-ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7; }

.custom-ReactTable .rt-thead .rt-header-pivot:after,
.custom-ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.custom-ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px; }

.custom-ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px; }

.custom-ReactTable .rt-tbody {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto; }

.custom-ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid grey; }

.custom-ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02); }

.custom-ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0; }

.custom-ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip; }

.custom-ReactTable .rt-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }

.custom-ReactTable .rt-tr {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.custom-ReactTable .rt-th,
.custom-ReactTable .rt-td {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity; }

.custom-ReactTable .rt-th.-hidden,
.custom-ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important; }

.custom-ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px; }

.custom-ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer; }

.custom-ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0); }

.custom-ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10; }

.custom-ReactTable .rt-tfoot {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15); }

.custom-ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05); }

.custom-ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0; }

.custom-ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03); }

.custom-ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05); }

.custom-ReactTable .-pagination {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1); }

.custom-ReactTable .-pagination input,
.custom-ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none; }

.custom-ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: white;
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none; }

.custom-ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default; }

.custom-ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff; }

.custom-ReactTable .-pagination .-previous,
.custom-ReactTable .-pagination .-next {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  color: white; }

.custom-ReactTable .-pagination .-center {
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5;
  flex: 5.5;
  text-align: center;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: flex-end; }

.custom-ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap; }

.custom-ReactTable .-pagination .-pageJump {
  display: inline-block; }

.custom-ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center; }

.custom-ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px; }

.custom-ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: white;
  border: 1px solid white; }

.custom-ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none; }

.custom-ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

.custom-ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all; }

.custom-ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%); }

.custom-ReactTable .rt-resizing .rt-th,
.custom-ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

a {
  color: white; }

.react-tabs {
  -webkit-tap-highlight-color: transparent; }
  .react-tabs__tab-list {
    margin: 0 0 10px;
    padding: 0; }
  .react-tabs__tab {
    padding: 7px 0;
    display: inline-block;
    bottom: -1px;
    position: relative;
    list-style: none;
    cursor: pointer;
    font-size: 20px;
    margin-right: 25px;
    font-weight: 500; }
    .react-tabs__tab:last-child {
      margin-right: 0; }
    .react-tabs__tab--selected {
      border-bottom: 2px solid #00ffe7;
      color: #ffffff; }
    .react-tabs__tab--selected-red {
      border-bottom: 2px solid #ff0009;
      color: #ffffff; }
    .react-tabs__tab--disabled {
      color: GrayText;
      cursor: default; }
    .react-tabs__tab:focus {
      outline: none; }
  .react-tabs__tab-panel {
    display: none; }
    .react-tabs__tab-panel--selected {
      display: block; }

.link-tab {
  padding: 7px 0;
  display: inline-block;
  bottom: -1px;
  position: relative;
  list-style: none;
  cursor: pointer;
  font-size: 20px;
  margin-right: 25px;
  font-weight: 500;
  color: white; }
  .link-tab:last-child {
    margin-right: 0; }
  .link-tab--selected-tirquise {
    border-bottom: 2px solid #00ffe7;
    color: #ffffff; }
  .link-tab--selected-red {
    border-bottom: 2px solid #f90b6a;
    color: #ffffff; }
  .link-tab--disabled {
    color: GrayText;
    cursor: default; }
  .link-tab:focus {
    outline: none; }

.mobile-nav {
  min-height: 100px;
  position: relative;
  /* Position and sizing of burger button */
  /* Color/shape of burger icon bars */
  /* Color/shape of burger icon bars on hover*/
  /* Position and sizing of clickable cross button */
  /* Color/shape of close button cross */
  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  /* General sidebar styles */
  /* Morph shape necessary with bubble or elastic */
  /* Wrapper for item list */
  /* Individual item */
  /* Styling of overlay */ }
  .mobile-nav .mobile-logo {
    width: 55px;
    height: 45px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%); }
  .mobile-nav .profile-holder {
    position: absolute;
    right: 25px;
    top: 35px; }
  .mobile-nav .mobile-nav__list li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  .mobile-nav .mobile-nav__list a {
    display: block;
    padding: 15px 30px 15px 0;
    font-size: 24px; }
  .mobile-nav .mobile-nav__list i {
    font-size: 14px;
    color: #00ffe7; }
  .mobile-nav .bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    right: 25px;
    top: 36px; }
  .mobile-nav .bm-burger-bars {
    background: #ffffff;
    height: 3px !important; }
  .mobile-nav .bm-burger-bars-hover {
    background: #a90000; }
  .mobile-nav .bm-cross-button {
    height: 30px !important;
    width: 30px !important;
    left: 20px;
    top: 30px !important; }
  .mobile-nav .bm-cross {
    height: 30px !important;
    width: 4px !important;
    background: #ffffff; }
  .mobile-nav .bm-menu-wrap {
    position: fixed;
    height: 100%; }
  .mobile-nav .bm-menu {
    background: #000000;
    padding: 100px 25px 35px;
    font-size: 1.15em; }
  .mobile-nav .bm-morph-shape {
    fill: #373a47; }
  .mobile-nav .bm-item-list {
    color: #b8b7ad; }
  .mobile-nav .bm-item {
    display: inline-block; }
  .mobile-nav .bm-overlay {
    background: rgba(0, 0, 0, 0.3); }

.music-header-title-and-image {
  position: absolute;
  bottom: -4rem;
  right: -4rem; }
  @media screen and (max-width: 768px) {
    .music-header-title-and-image {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0; } }
  @media screen and (max-width: 992px) {
    .music-header-title-and-image {
      bottom: -2rem;
      right: 0rem; } }

.css-2b097c-container {
  width: 100% !important;
  font-size: 17px;
  justify-content: space-around; }
  .css-2b097c-container img {
    margin-right: 10px; }

.css-yk16xz-control {
  height: 30px !important;
  border: none !important;
  outline: none !important;
  background: transparent !important; }

.css-1uccc91-singleValue {
  min-width: 100%; }

.customOption {
  border-bottom: 0;
  background: transparent !important; }
  .customOption img {
    width: 30px;
    height: 20px; }

.css-1okebmr-indicatorSeparator {
  display: none; }

.css-w8afj7-Input {
  height: 30px;
  outline: none;
  max-width: 175px;
  width: 100%;
  transition: all 0.3s ease;
  font-size: 17px;
  font-weight: normal; }

.css-109p05i-control {
  border: none !important; }

.css-2b097c-container {
  border-bottom: none !important; }

.application-inline-loader {
  margin-left: 2.5em;
  margin-right: 2.5em;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff; } }

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff; } }

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }

.lds-ripple div {
  position: absolute;
  border: 4px solid #00ffe7;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s; }

@keyframes lds-ripple {
  0% {
    top: 56px;
    left: 56px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 116px;
    height: 116px;
    opacity: 0; } }

.auth-page-layout__wrapper {
  height: 100vh;
  position: relative; }
  .auth-page-layout__wrapper .input-field {
    color: #000000;
    max-width: 175px;
    font-weight: 400;
    background: transparent; }
    @media (max-width: 500px) {
      .auth-page-layout__wrapper .input-field {
        max-width: 100%; } }
  .auth-page-layout__wrapper .forgot-password .form-holder {
    max-width: 370px; }
  .auth-page-layout__wrapper .forgot-password .label-txt {
    max-width: 95px; }
  .auth-page-layout__wrapper .forgot-password .input-field {
    max-width: 195px; }
  .auth-page-layout__wrapper .forgot-password .btn {
    margin-top: 50px; }
  .auth-page-layout__wrapper .label-txt {
    color: #000000;
    text-align: right;
    min-width: 115px; }
    @media (max-width: 500px) {
      .auth-page-layout__wrapper .label-txt {
        min-width: 100px; } }
  .auth-page-layout__wrapper .btn {
    background: #a4896c;
    margin: 0 auto;
    margin-top: 35px; }
    .auth-page-layout__wrapper .btn:hover {
      background: #896f55; }

.auth-logo {
  position: absolute;
  right: 50px;
  bottom: 40px;
  width: 175px;
  height: 67px; }
  .auth-logo > img {
    width: 100%;
    height: 100%; }
  @media (max-width: 1024px) {
    .auth-logo {
      display: none; } }

.auth-page-layout__content {
  background: #fff;
  min-height: 100%;
  max-width: 620px;
  margin: 0 auto;
  transform: skew(-5deg);
  text-align: center;
  padding: 145px 0 100px;
  color: #323232; }
  @media (max-width: 500px) {
    .auth-page-layout__content {
      transform: skew(0deg);
      display: flex;
      align-items: center; } }

.auth-page-layout__content--holder {
  margin: 0 auto;
  width: 85%;
  transform: skew(5deg); }
  @media (max-width: 500px) {
    .auth-page-layout__content--holder {
      transform: skew(0deg); } }

.title-primary {
  color: #a4896c;
  margin-bottom: 30px; }

.title-subtext {
  padding: 0 20px; }
  .title-subtext.bold {
    margin-bottom: 20px;
    font-weight: bold; }
  .title-subtext.welcome {
    padding: 0 80px;
    margin-bottom: 30px; }
  .title-subtext.confirmation {
    margin-top: 10px; }

.auth-link-text {
  color: #a4896c;
  font-weight: bold; }
  .auth-link-text:hover {
    text-decoration: underline; }

.form-holder {
  margin: 20px auto;
  max-width: 350px;
  width: 100%;
  padding: 0 20px; }
  @media (max-width: 500px) {
    .form-holder {
      padding: 0; } }

.back-link {
  margin-top: 45px;
  display: block; }

.password-reset .btn {
  margin-top: 80px;
  margin-bottom: 50px; }

.reset-password .form-holder {
  margin-bottom: 50px; }

.signed-out .title-subtext {
  margin-bottom: 70px; }

.input-field {
  color: white;
  max-width: 175px;
  font-weight: 400;
  background: transparent; }

.forgot-password .form-holder {
  max-width: 370px; }

.forgot-password .label-txt {
  max-width: 95px; }

.forgot-password .input-field {
  max-width: 195px; }

.forgot-password .btn {
  margin-top: 50px; }

.forgot-pass {
  text-align: right; }
  .forgot-pass a {
    font-size: 14px;
    color: #323232; }
    .forgot-pass a:hover {
      text-decoration: underline; }

.sign-in .input-field__holder:nth-child(2) {
  margin-bottom: 0; }

.admin__edit label {
  justify-content: flex-start; }

.admin__edit .label-txt {
  text-align: left;
  color: #ff0c69;
  min-width: 100px; }

.admin__edit .input-field {
  max-width: 200px;
  color: #ffffff;
  font-family: 'Arial'; }
  .admin__edit .input-field:focus {
    border-bottom: 0.5px solid #ffffff; }

.admin__edit .input-field__holder {
  margin: 25px 0; }

.admin__edit .inputs-right label {
  justify-content: flex-end; }
  @media (max-width: 768px) {
    .admin__edit .inputs-right label {
      justify-content: flex-start; } }

.admin .label-txt {
  text-align: left;
  color: #ff0c69;
  min-width: 100px; }

.admin .input-field {
  max-width: 200px;
  color: #ffffff;
  font-family: 'Arial'; }
  .admin .input-field:focus {
    border-bottom: 0.5px solid #ffffff; }

.admin .input-field__holder {
  margin: 25px 0; }

.admin .inputs-right label {
  justify-content: flex-end; }

.axe-music-header .axe-introduction {
  padding-top: 9rem;
  padding-left: 9rem; }
  @media (max-width: 990px) {
    .axe-music-header .axe-introduction {
      padding: 1rem; } }
