.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    margin: 0 0 10px;
    padding: 0;
  }

  &__tab {
    padding: 7px 0;
    display: inline-block;
    bottom: -1px;
    position: relative;
    list-style: none;
    cursor: pointer;
    font-size: 20px;
    margin-right: 25px;
    font-weight: 500;

    &:last-child {
      margin-right: 0;
    }

    &--selected {
      border-bottom: 2px solid $tirques;
      color: $white;
    }

    &--selected-red {
      border-bottom: 2px solid $red;
      color: $white;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}

.link-tab {
  padding: 7px 0;
  display: inline-block;
  bottom: -1px;
  position: relative;
  list-style: none;
  cursor: pointer;
  font-size: 20px;
  margin-right: 25px;
  font-weight: 500;
  color: white;

  &:last-child {
    margin-right: 0;
  }

  &--selected-tirquise {
    border-bottom: 2px solid $tirques;
    color: $white;
  }

  &--selected-red {
    border-bottom: 2px solid #f90b6a;
    color: $white;
  }

  &--disabled {
    color: GrayText;
    cursor: default;
  }

  &:focus {
    outline: none;
  }
}
