.music-header-title-and-image {
  position: absolute;
  bottom: -4rem;
  right: -4rem;
  @media screen and (max-width: 768px) {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
  @media screen and (max-width: 992px) {
    bottom: -2rem;
    right: 0rem;
  }
}